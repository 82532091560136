<template>
  <div style="background-color: #f0f0f2">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9" style="min-height: 60vh">
          <div class="pb-md-5">
            <!-- 查詢區間 -->
            <div class="pt-3 pt-lg-5">
              <div
                class="fw-bolder ps-2 pe-1 py-3 duration w-65 w-ssm-55 w-sm-50 w-md-35 w-xl-30 w-xxl-25"
              >
                <swiper
                  :allowTouchMove="false"
                  :slidesPerView="1"
                  :navigation="{
                    nextEl: '#js-next1',
                    prevEl: '#js-prev1',
                  }"
                >
                  <swiper-slide
                    class="text-center text-nowrap"
                    v-for="item in dateInterval"
                    :key="item.startDate"
                    >{{ item.startDate }}~{{ item.endDateUI }}</swiper-slide
                  >
                  <!-- navigation buttons -->
                  <div
                    id="js-next1"
                    class="swiper-button-prev"
                    @click.prevent="changeTimePrev"
                  ></div>
                  <div
                    id="js-prev1"
                    class="swiper-button-next"
                    @click.prevent="changeTimeNext"
                  ></div>
                </swiper>
              </div>
              <div class="text-center fs-3 bg-white py-3" v-if="!order[0]">
                此區間沒有訂單
              </div>
              <!-- 訂單編號Accordionclick -->
              <div class="circle">
                <div
                  class="pt-2 pb-5"
                  v-for="(item, orderNo) in order"
                  :key="orderNo + '1'"
                >
                  <div
                    class="rounded-3 bg-white border-none position-relative cardShadow arrow px-4 px-md-11 py-4"
                  >
                    <table class="table table-hover table-borderless m-0">
                      <tbody>
                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">訂單日期</th>
                          <td class="text-secondary">
                            {{ item.shoppingDate.split("T")[0] }}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">訂單編號</th>
                          <td>{{ item.orderNo }}</td>
                        </tr>
                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">訂單金額</th>
                          <td>${{ $currency.currency(item.paidAmount) }}</td>
                        </tr>
                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">付款方式</th>
                          <td>{{ item.paymentMethodName }}</td>
                        </tr>

                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">收件地址</th>
                          <td>
                            {{ filterCountyAndTown( item.city, item.district)}}  {{ item.address }}</td>
                        </tr>

                        <tr>
                          <th scope="row" class="ps-0 w-lg-20 w-40">訂單狀態</th>
                          <td>{{ item.orderStatusName }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex mt-1 justify-content-between align-items-center">
                      <!-- <div class="d-flex mt-1" > -->
                      <div class="w-50">
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#cancelOrder"
                          href="#"
                          @click.prevent="callCancelList(item.orderNo)"
                          v-if="item.canCancelOrder"
                        >
                          <span class="fw-bolder p-1 bg-gray cancelbutton rounded-3 fs-7"
                            >X 取消訂單</span
                          >
                        </a>
                      </div>
                      <div class="bg-primary px-2 rounded">
                        <div
                          class="order-detail collapsed text-white d-flex align-items-center fs-7"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="`#${item.orderNo}`"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          style="width: fit-content"
                        >
                          <span class="me-1">訂單詳情</span
                          ><i class="bi bi-chevron-down fs-5"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 訂單展開Accordion-已購項目 -->
                  <div class="accordion accordion-flush position-relative">
                    <div class="col-auto accordion money">
                      <div class="accordion-collapse collapse" :id="`${item.orderNo}`">
                        <div>
                          <div class="pb-2">
                            <div class="cardShadow">
                              <div
                                class="fw-bolder text-white bg-primary px-4 px-md-11 py-2 fs-5"
                              >
                                訂單明細
                              </div>
                              <div
                                class="rounded-bottom bg-white border-none px-4 px-md-11 py-7 tableBottomLIne"
                              >
                                <h6 class="fw-bold mb-3 fs-10">商品名稱</h6>
                                <div class="pb-4 border-bottom border-2">
                                  <table class="table table-borderless m-0">
                                    <tbody>
                                      <tr
                                        v-for="product in item.product"
                                        :key="product.categoryCode + '1'"
                                      >
                                        <th
                                          scope="row"
                                          class="fw-bolder py-3 px-0 w-35 w-ssm-20 w-xl-10"
                                        >
                                          <div>
                                            <img
                                              :src="product.image"
                                              alt=""
                                              class="w-100"
                                            />
                                          </div>
                                        </th>
                                        <td
                                          class="px-4 px-ssm-5 px-sm-6 py-3 w-70 w-xl-50"
                                        >
                                          {{ product.productNo }}
                                        </td>
                                        <td class="py-3">x{{ product.quantity }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="py-7 border-bottom border-2">
                                  <table class="table table-borderless m-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          商品數量
                                        </th>
                                        <td>{{ item.product.length }}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          小計
                                        </th>
                                        <td>
                                          ${{ $currency.currency(item.payableAmount) }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          點數折抵
                                        </th>
                                        <td>${{ item.pointDiscount }}</td>
                                      </tr>
                                      <tr v-if="item.bonus">
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          紅利折抵
                                        </th>
                                        <td>${{ item.bonus }}</td>
                                      </tr>
                                      <tr>
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          實付金額
                                        </th>
                                        <td>
                                          ${{ $currency.currency(item.paidAmount) }}
                                        </td>
                                      </tr>
                                      <template v-if="item.shipping">
                                        <tr>
                                          <th scope="row" class="w-40 w-lg-20 ps-0">
                                            物流編號
                                          </th>
                                          <td>{{ item.shippingNo }}</td>
                                        </tr>
                                        <tr>
                                          <th scope="row" class="w-40 w-lg-20 ps-0">
                                            配送物流
                                          </th>
                                          <td>
                                            <a
                                              class="text-primary"
                                              :href="item.shippingUrl"
                                              target="_blank"
                                              >{{ item.shipping }}</a
                                            >
                                          </td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="pt-4">
                                  <table class="table table-borderless m-0">
                                    <tbody>
                                      <tr>
                                        <th scope="row" class="w-40 w-lg-20 ps-0">
                                          發票編號
                                        </th>
                                        <td v-if="item.invoiceNo">
                                          {{ item.invoiceNo }}<br /><span class="fs-7"
                                            >發票中獎將於開獎日翌日起10日內以MAIL寄出通知<br />發票如有錯誤，請於當月底前退還，逾期恕不受理。</span
                                          >
                                        </td>
                                        <td v-else>發票未開立<br /></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 手機板將保護說明移至下方 -->
              <div class="declareMobile">
                <span class="d-block fs-6 p-2 p-md-0" style="text-align: justify"
                  >依照消費者保護法規定，消費者均享有產品到貨七天猶豫期之權益(注意！猶豫期非試用期)，如欲退回產品，請確保商品必須是未使用過、全新狀態且包裝完整(特殊提供試用品或其他退貨限制，會另於網頁標示)，否則恕不接受退貨。超過七天猶豫期之訂單，則不接受退貨/換貨服務。<br />如單筆訂單內二項(含)以上商品，恕不提供單項商品取消/退貨。若有特殊需求，請聯絡客服我們將有專人盡速為您處理。
                </span>
              </div>
              <!-- Modal returnedPurchase 退貨彈窗-->
              <div
                class="modal fade"
                id="cancelOrder"
                tabindex="-1"
                data-bs-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content p-3">
                    <div
                      class="modal-header d-flex"
                      style="align-item: center; justify-content: center"
                    >
                      <p class="modal-title fs-6 fw-bolder" id="exampleModalLabel">
                        我們立即為您辦理退貨申請，並請您留意以下資訊
                      </p>
                      <button
                        type="button "
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body p-2">
                      <form
                        class="d-flex flex-column justify-content-center align-items-center"
                      >
                        <div class="mb-3 d-flex flex flex-column">
                          <div class="d-flex">
                            <label for="" class="pt-1">1.</label
                            ><label
                              for="recipient-name"
                              class="col-form-label text-break m-0 text-secondary pt-1"
                              style=""
                              >網站僅提供退貨申請服務，無提供換貨。</label
                            >
                          </div>
                          <div class="d-flex">
                            <label for="" class="pt-1">2.</label
                            ><label
                              for="recipient-name"
                              class="col-form-label text-break m-0 text-secondary pt-1"
                              >若已收到產品，請務必將商品恢復為寄出時的包裝，所有訂購商品、配件、贈品及包裝盒也需完整置入原外裝箱中。我們將請貨運到府回收。</label
                            >
                          </div>
                          <div class="d-flex">
                            <label for="" class="pt-1">3.</label
                            ><label
                              for="recipient-name"
                              class="col-form-label text-break m-0 text-secondary pt-1"
                              >整筆訂單如有參加折價券、滿額折抵活動，部分商品申請退貨後將按折扣金額等比例退還。</label
                            >
                          </div>
                          <div class="d-flex">
                            <label for="" class="pt-1">4.</label
                            ><label
                              for="recipient-name"
                              class="col-form-label text-break m-0 text-secondary pt-1"
                              >請留意訂單取消/退貨後，則該優惠活動將無法重新享有(例折價券、購物金等..)</label
                            >
                          </div>
                          <div class="d-flex">
                            <label for="" class="pt-1">5.</label
                            ><label
                              for="recipient-name"
                              class="col-form-label text-break m-0 text-secondary pt-1"
                              >待貨運收回商品，確認商品無誤後，將儘速為您辦理退款。</label
                            >
                          </div>
                        </div>
                        <div
                          class="d-flex flex-column justify-content-center align-items-center"
                          style="width: 100%"
                        >
                          <div
                            class="row d-flex justify-content-center align-items-center m-2"
                            style="width: 100%"
                          >
                            <div class="col-3 p-0">申請理由:</div>
                            <select
                              name=""
                              id=""
                              class="col-9 styled-1 form-control"
                              v-model="cancelOrder.cancelCode"
                            >
                              <option value="">請選擇</option>
                              <option
                                v-for="item in cancelList"
                                :key="item.cancelCode + 1"
                                :value="item.cancelCode"
                              >
                                {{ item.cancelDescription }}
                              </option>
                            </select>
                          </div>
                          <button
                            class="btn btn-outline-primary text-primary shadow-none"
                            data-bs-dismiss="modal"
                            @click.prevent="returnedPurchase"
                          >
                            確定
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer border-top-0"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="declare ps-2 pe-1 py-3">
              <span class="d-none d-md-block fs-6 p-2 p-md-0" style="text-align: justify"
                >依照消費者保護法規定，消費者均享有產品到貨七天猶豫期之權益(注意！猶豫期非試用期)，如欲退回產品，請確保商品必須是未使用過、全新狀態且包裝完整(特殊提供試用品或其他退貨限制，會另於網頁標示)，否則恕不接受退貨。超過七天猶豫期之訂單，則不接受退貨/換貨服務。<br />如單筆訂單內二項(含)以上商品，恕不提供單項商品取消/退貨。若有特殊需求，請聯絡客服我們將有專人盡速為您處理。
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- loading 動畫 -->
    <loading :active="cancelOrderSucess">
      <div class="loading1">
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
        <div class="demo1"></div>
      </div>
    </loading>
  </div>
</template>

<script>
import moment from 'moment'
import shippingSupplier from '@/assets/shippingUrl.json'
import countryName from '@/assets/country.json'
// import moment from 'moment'
export default {
  data () {
    return {
      countryName, // ?縣市鄉鎮代碼 json
      shippingSupplier: shippingSupplier,
      isloading: false,
      moment: new Date(),
      today: '',
      oldday: '',
      order: [], // ?原始訂單
      orderSelected: '2', // ? 訂單查詢預設為訂單已付款
      orderSelectedChanged: '', // ? 訂單查詢切換狀態
      id: '',
      year: '',
      month: '',
      day: '',
      dateInterval: [
        {
          startDate: '',
          endDateUI: '',
          endDateAPI: ''
        },
        {
          startDate: '',
          endDateUI: '',
          endDateAPI: ''
        },
        {
          startDate: '',
          endDateUI: '',
          endDateAPI: ''
        },
        {
          startDate: '',
          endDateUI: '',
          endDateAPI: ''
        }
      ],
      startDate: '',
      endDate: '',
      dateIntervalNum: 0,
      cancelList: [],
      cancelOrder: {
        orderNo: '',
        cancelCode: ''
      },
      // shippingSupplier: shippingSupplier, // ? 物流廠商Json檔
      // isRouterAlive: true // ?頁面刷新判斷值
      cancelOrderSucess: false // ?loading動畫判斷值
    }
  },
  methods: {
    filterCountyAndTown (cityCode, townCodde) {
      const result = countryName.filter((item) => {
        if (item.Country === cityCode && item.District === townCodde) { return item }
      })

      return `${result[0].CountryName}${result[0].DistrictName}`
    },
    getTime () {
      // ?獲取本機時間並運算轉換(4個時間區間)
      this.dateInterval.forEach((item, index) => {
        item.startDate = moment()
          .add(-2 - index * 3, 'month')
          .format('YYYY-MM-01')
        item.endDateUI = moment()
          .add(1 - index * 3, 'month')
          .format('YYYY-MM-01')
        item.endDateAPI = moment()
          .add(1 - index * 3, 'month')
          .format('YYYY-MM-01')
      })
      this.startDate = this.dateInterval[this.dateIntervalNum].startDate
      this.endDate = this.dateInterval[this.dateIntervalNum].endDateAPI
      this.getOrder()
    },
    changeTimePrev () {
      this.dateIntervalNum += 1
      this.getTime()
    },
    changeTimeNext () {
      this.dateIntervalNum -= 1
      this.getTime()
    },
    getOrder () {
      // ?後端拉單
      this.order = []
      const url = `${process.env.VUE_APP_API}/api/members/orders?startdate=${this.startDate}&enddate=${this.endDate}`
      this.cancelOrderSucess = true
      this.axios
        .get(url)
        .then((res) => {
          this.cancelOrderSucess = false
          if (res.data.rtnCode === 0) {
            this.order = res.data.info
            this.order.forEach((item) => {
              item.product.forEach((item2) => {
                if (item2.shipping) {
                  item.shipping = item2.shipping
                  item.shippingNo = item2.shippingNo
                  item.shippingUrl = shippingSupplier[item2.shipping]
                }
              })
            })
          } else if (res.data.rtnCode === 1398) {
            // ? 該區間沒有訂單
            this.order = {}
          } else {
            this.$swal.fire({
              title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
              confirmButtonColor: '#003894',
              customClass: {
                title: 'text-class'
              }
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    pickOrderStatus () {
      // ? 顯示目前所篩選訂單
      this.orderSelectedChanged = this[`order${this.orderSelected}`]
      // console.log(this.orderSelected1)
      // this.orderSelectedChanged[0].product[0].shipping = '大智通'
      // this.orderSelectedChanged[0].product[0].shippingNo = '123123'
      // this.getShippingUrl()
      // this.reload()
    },
    callCancelList (orderNo) {
      // ?呼叫刪除訂單彈窗API
      this.cancelOrder.orderNo = orderNo
      const url = `${process.env.VUE_APP_API}/api/order/cancelList`
      this.axios.get(url).then((res) => {
        this.cancelList = res.data.info
      })
    },
    async returnedPurchase () {
      // ?確定刪除訂單
      if (!this.cancelOrder.cancelCode) {
        this.$swal.fire({
          position: 'center',
          title: '請選擇申請事由',
          showConfirmButton: true,
          confirmButtonColor: '#003894',
          customClass: {
            title: 'text-class'
          }
        })
        return
      }
      const url = `${process.env.VUE_APP_API}/api/order/cancel`
      this.cancelOrderSucess = true
      const res = await this.$http.post(url, this.cancelOrder)
      if (res.data.rtnCode === 0) {
        this.cancelOrderSucess = false
        this.$swal
          .fire({
            position: 'center',
            title: '您的訂單已取消',
            showConfirmButton: true,
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$router.go() // ?訂單取消刷新頁面
            }
          })
        // setTimeout(() => {
        //   this.reload() // ?訂單取消刷新頁面
        // }, 800)
      } else {
        this.cancelOrderSucess = false
        setTimeout(() => {
          this.$swal.fire({
            position: 'center',
            title: `${res.data.rtnMsg}(錯誤代碼${res.data.rtnCode})`,
            showConfirmButton: true,
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
        }, 100)
      }
    },
    goToEreceipt () {
      // ?電子發票彈窗
      const receiptRoute = this.$router.resolve({ path: '/ereceipt' })
      window.open(receiptRoute.href, '_blank')
    },
    sendDoubtOrder (order) {
      // ?訂單帶入連絡欄位
      this.$swal.fire({
        title: '已將該筆訂單帶入問題欄位',
        showConfirmButton: true,
        confirmButtonColor: '#003894',
        customClass: {
          title: 'text-class'
        }
      })
      setTimeout(() => {
        this.$router.push('/memberboard/contactus')
        this.$store.commit('askOrder', order)
      }, 2000)
    },
    sendDoubtItem (item) {
      // ?訂單內產品帶入連絡欄位
      this.$swal.fire({
        title: '已將該項產品帶入問題欄位',
        showConfirmButton: true,
        confirmButtonColor: '#003894',
        customClass: {
          title: 'text-class'
        }
      })
      setTimeout(() => {
        this.$router.push('/memberboard/contactus')
        this.$store.commit('askProduct', item)
      }, 2000)
    }
    // urlOpen (url) { // ? 另開視窗前往廠商頁面進行包裹查詢
    //   if (url) {
    //     window.open(url, '_blank')
    //   }
    // }
    // reload () { // ?刷新畫面
    //   this.isRouterAlive = false
    //   this.$nextTick(() => {
    //     this.isRouterAlive = true
    //   })
    // }
  },
  mounted () {
    this.getTime()
  }
  // mounted () {
  //   this.getOrder()
  // }
}
</script>

<style lang="scss" scoped>
//查詢區間灰底
.duration {
  background-color: #f0f0f2;
}
.accordion-list {
  background-color: #0000;
  box-shadow: none;
}
//取消訂單點擊文字
.btn-close {
  // margin: 0 93%;原本的樣式
  padding: 0.2rem 0.2rem;
  width: 1.5em;
  height: 1.5em;
  opacity: 0.8;
}
//更改彈窗XX樣式
.modal-header .btn-close {
  margin: -3rem -1rem -2rem auto;
  @media screen and (max-width: 768px) {
    margin: -6rem -1.8rem -1rem auto;
  }
}
//更改Accordion箭頭樣式
.accordion-button {
  background-color: rgba(255, 255, 255, 0) !important;
  &:after {
    transform: rotate(360deg) !important;
  }
}
.accordion-button:not(.collapsed) {
  &:after {
    transform: rotate(180deg) !important;
    // background-image: url(../assets/img/member-dash-circle-fill.svg);
    background-color: rgba(255, 255, 255, 0) !important;
    // background-color: rgba(255,255,255,0.5) !important;;
  }
}
.accordion-button:focus {
  background: none;
}
.accordion-button::after {
  margin-left: 0% !important;
  // background-image: url(../assets/img/member-plus-circle-fill.svg);
  width: 1.5em;
  height: 1.5em;
  background-size: 1.5em;
}
#accordion-button_1 {
  //修改accordion預設width
  width: 0% !important;
}
.space {
  /* 訂單編號推空間手機板消失 */
  display: none;
}
.btn {
  /* 按鈕hover效果 */
  &:hover {
    color: #fff !important;
  }
}
.sendDoubtOrder {
  justify-content: center;
  @media screen and (max-width: 768px) {
    justify-content: end;
  }
}
.sendDoubtItem {
  justify-content: center;
  @media screen and (max-width: 768px) {
    justify-content: end;
  }
}
.declare {
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.declareMobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}
.dropdown-toggle::after {
  margin-left: 65% !important;
}
.styled-1 {
  &:focus {
    outline: none;
  }
}
.cardShadow {
  box-shadow: 5px 5px 5px #adb5bd;
}
// *訂單迴圈線條樣式
// .circle {
  // .accordion-flush {
    // z-index: 99;
  // }
  // .arrow {
    /* &::after{
          content: '' ;
          // z-index: 0;
          width: 100%;
          border-bottom: 1.5px solid #ced4da;
          position: absolute;
          left: 50%;
          bottom:-10%;
          transform: translate(-50%, -50%);
        } */
    // &:last-of-type {
    //   &::after{
    //     content: '';
    //     background-image: none;
    //     position: absolute;
    //   }
    // }
  // }
  /* .money {
        .show{
          &::after{
            content: '' ;
            z-index: 0;
            width: 100%;
            border-bottom: 2.5px solid #003894;
            border-radius: 2px;
            position: absolute;
            bottom:-2%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 1.5px 1.5px 1.5px #adb5bd;
          }
        }
      } */
// }
.table {
  color: #4d4d4d;
  td {
    text-align: justify;
  }
}
/* .tableBottomLIne div {
      position: relative;
        &::after {
          position: absolute;
          top: 100%;
          left: 5%;
          content: "";
          border: 1px solid #adb5bd;
          border-radius: 2px 2px 2px 2px;
          width: 90%;
        }
  } */
.tableBottomLIne:last-child div {
  position: relative;
  &::after {
    position: absolute;
    top: 100%;
    left: 5%;
    content: "";
    border-radius: 2px 2px 2px 2px;
    width: 90%;
    border: none;
  }
}
.cancelbutton {
  color: #4d4d4d;
  &:hover {
    color: white;
    background-color: #4d4d4d !important;
  }
}
::v-deep.swiper-button-next,
.swiper-button-prev {
  color: #000;
  width: 0;
}

::v-deep.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px;
}
// ===loading動畫樣式===
@keyframes ldio-mhpvl42jmen {
  0% {
    top: 99.54px;
    left: 99.54px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 14.22px;
    left: 14.22px;
    width: 170.64000000000001px;
    height: 170.64000000000001px;
    opacity: 0;
  }
}
.ldio-mhpvl42jmen div {
  position: absolute;
  border-width: 18.96px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-mhpvl42jmen 1.639344262295082s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-mhpvl42jmen div:nth-child(1) {
  border-color: #003894;
  animation-delay: 0s;
}
.ldio-mhpvl42jmen div:nth-child(2) {
  border-color: #808080;
  animation-delay: -0.819672131147541s;
}
.loadingio-spinner-ripple-m2og6xu1j8q {
  width: 237px;
  height: 237px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-mhpvl42jmen {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-mhpvl42jmen div {
  box-sizing: content-box;
}
.order-detail:not(.collapsed) {
  .bi-chevron-down {
    transform: rotate(180deg);
  }
}
.bi-chevron-down {
  transition: transform 0.2s ease-in-out;
}
</style>
